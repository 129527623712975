
.browser-alert-wrapper{
    height: 100%;
}


.browser-alert {

    width:575px;
    font-size: 0.95rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: -2px 0px 19px 1px rgba(0, 0, 0, 0.41);
    height: 220px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    h1{
        font-size:1.3rem;
        font-weight:bold;
    }
}
